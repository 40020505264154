<template>
    <div class="scan-page">

        <!-- Barcode picker -->
        <scanner @barcodeHasBeenScanned="barcodeWasScanned($event)"></scanner>

        <!-- Empty state -->
        <empty-state
                v-if="!shipmentIdBeingReceived"
                icon="scanner"
                title="Scan a shipment">
        </empty-state>

        <!-- Inbound shipment scanned --->
        <template v-if="shipmentIdBeingReceived">
            <!-- Details -->
            <div class="scan-details">
                <div class="container">
                    <h4 class="d-flex align-items-center mb-2">Shipment {{ shipmentIdBeingReceived }} <button class="btn btn-info btn-sm ml-auto" @click="clearReceivedShipmentScanned">Change</button></h4>
                    <h5 class="text-subtitle mb-0">{{ repairKitsTechnicianReceived.length }} of {{ repairKits.length }} repair kits scanned</h5> <!-- TODO: Update the item count -->
                </div>
            </div>

            <!-- Item list -->
            <div class="container scan-content">
                <b-tabs>

                    <!-- To be scanned -->
                    <b-tab title="To be scanned" active>
                        <div class="card">
                            <ul class="checklist my-0" v-if="repairKitsPacked.length > 0">
                                <li
                                        v-for="repairKit in repairKitsPacked"
                                        :key="repairKit.id"
                                        :class="{'complete': repairKit.state_constant === 'TECHNICIAN_RECEIVED'}"
                                        class="checklist-item no-hover">
                                    <div>Repair kit: {{ repairKit.id }}</div>
                                </li>
                            </ul>
                            <empty-state
                                    v-if="repairKitsPacked.length < 1"
                                    emptyIcon
                                    icon="stock-pick"
                                    inline
                                    small
                                    title=""
                                    subtitle="No repair kits to be scanned">
                            </empty-state>
                        </div>
                    </b-tab>

                    <!-- Scanned -->
                    <b-tab title="Scanned">
                        <div class="card">
                            <ul class="checklist my-0" v-if="repairKitsTechnicianReceived.length > 0">
                                <li
                                        v-for="repairKit in repairKitsTechnicianReceived"
                                        :key="repairKit.id"
                                        :class="{'complete': repairKit.state_constant === 'TECHNICIAN_RECEIVED'}"
                                        class="checklist-item no-hover">
                                    <div>Repair kit: {{ repairKit.id }}</div>
                                </li>
                            </ul>
                            <empty-state
                                    v-if="repairKitsTechnicianReceived.length < 1"
                                    emptyIcon
                                    icon="stock-pick"
                                    inline
                                    small
                                    title=""
                                    subtitle="No repair kits have been scanned">
                            </empty-state>
                        </div>
                    </b-tab>

                    <!-- All kits -->
                    <b-tab title="All kits">
                        <div class="card">
                            <ul class="checklist my-0" v-if="repairKits.length > 0">
                                <li
                                        v-for="repairKit in repairKits"
                                        :key="repairKit.id"
                                        :class="{'complete': repairKit.state_constant === 'TECHNICIAN_RECEIVED'}"
                                        class="checklist-item no-hover">
                                    <div>Repair kit: {{ repairKit.id }}</div>
                                </li>
                            </ul>
                            <empty-state
                                    v-if="repairKits.length < 1"
                                    emptyIcon
                                    icon="stock-pick"
                                    inline
                                    small
                                    title=""
                                    subtitle="No repair kits in this shipment">
                            </empty-state>
                        </div>
                    </b-tab>

                </b-tabs>
            </div>
        </template>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                pageTitle: null,
            }
        },

        computed: {
            ...mapGetters([
                'appRegion',
                'repairKitForFsl',
                'repairKits',
                'repairKitsPacked',
                'repairKitsTechnicianReceived',
                'shipmentIdBeingReceived',
            ]),
        },

        mounted() {
            this.clearReceivedShipmentScanned();
        },

        methods: {
            ...mapActions([
                'clearReceivedShipmentScanned',
                'displayToast',
                'loadReceivedShipmentDetails',
                'setReceivedShipmentScanned',
                'setRepairKitAsTechnicianReceived',
                'setRepairKitAsTechnicianReceivedDirectly',
                'loadRepairKitForFsl',
            ]),

            /** Check the barcode type */
            barcodeWasScanned(barcodeScanned) {
                if (barcodeScanned.type === 'shipment') {
                    this.shipmentScanned(barcodeScanned);
                }

                if (barcodeScanned.type === 'repairKit') {
                    this.loadRepairKitForFsl({
                        repairKitId: barcodeScanned.id.toString()
                    }).then(() => {
                        if (this.repairKitForFsl.fsl_kit_id) {
                            if (this.repairKitForFsl.state_constant === 'TECHNICIAN_RECEIVED') {
                                this.displayToast({text: 'This kit has already been scanned', type: 'error'});
                                return;
                            }

                            this.setRepairKitAsTechnicianReceivedDirectly(this.repairKitForFsl.id).then(() => {
                                this.displayToast({text: 'This FSL kit has been marked as received', type: 'success'});
                            });
                        } else {
                            if (!this.shipmentIdBeingReceived) {
                                this.displayToast({text: 'Please scan a shipment first', type: 'error'});
                                return;
                            }

                            this.repairKitScanned(barcodeScanned);
                        }
                    });
                }
            },

            /** Check if shipment is complete */
            checkShipmentComplete() {
                if (this.repairKits.length === this.repairKitsTechnicianReceived.length) {
                    this.clearReceivedShipmentScanned();
                    this.displayToast({text: 'Shipment packing complete', type: 'success'});
                }
            },

            /** Repair kit scanned */
            repairKitScanned(barcodeScanned) {
                let kitBelongsToShipment = false;

                this.repairKits.forEach(repairKit => {
                    if (barcodeScanned.id.toString() === repairKit.id.toString()) {
                        kitBelongsToShipment = true;

                        if (repairKit.state_constant === 'TECHNICIAN_RECEIVED') {
                            this.displayToast({text: 'This kit has already been scanned', type: 'error'});
                            return;
                        }

                        this.setRepairKitAsTechnicianReceived(repairKit.id)
                            .then(() => {
                                this.checkShipmentComplete();
                            });
                    }
                });

                if (!kitBelongsToShipment) {
                    this.displayToast({text: 'This kit does not belong to this shipment', type: 'error'});
                }
            },

            /** Shipment scanned */
            shipmentScanned(barcodeScanned) {
                this.clearReceivedShipmentScanned();
                this.loadReceivedShipmentDetails({
                    barcodeScanned:barcodeScanned
                }).then(() => {
                    if (this.repairKitsPacked.length === 0) {
                        this.displayToast({text: 'This shipment has already been scanned', type: 'error'});
                        return;
                    }

                    this.setReceivedShipmentScanned(barcodeScanned.id);
                });
            }
        }
    }
</script>

<style scoped>

</style>